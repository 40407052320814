// CatalogoReporteBarras.js
import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import { Card, Accordion, Group, Button, Text } from '@mantine/core';
import BarChartComponent from '../components/BarChart';
import CasosFiltro from '../components/CasosFiltro';
import { useDispatch } from 'react-redux';
import moment from "moment";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { IconSearch } from '@tabler/icons-react';
import PieChartComponent from '../components/PieChartComponent';


function CatalogoReporteBarras({ user, permisos }) {
  const dispatch = useDispatch();
  const [showChart, setShowChart] = useState(false);
  const [casos, setCasos] = useState([])



  const [filters, setFilters] = useState({
    fechaInicial: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Primer día del mes actual,
    fechaFinal: new Date(),
    nombre_unidad: "",
    nombre_paciente: "",
    nombre_enfermero: "",
    nombre_etapa_caso: "",

  });

  const fetchTiposCasos = async () => {
    try {
      let supabaseQuery = supabase
        .from('casos')
        .select(`
          id_caso,
          fecha_caso_inicio,
          fecha_caso_fin,
          numero_caso,
          id_unidad,
          cat_unidades!inner (
              id_unidad,
              nombre_unidad
              ),
          id_etapa_caso,
          cat_etapas_casos!inner (
              id_etapa_caso,
              nombre_etapa_caso
              ),
          id_paciente,
          cat_pacientes!inner (
              id_paciente,
              nombre_paciente
              ),
          id_enfermero,
          cat_enfermeros!inner (
              id_enfermero,
              nombre_enfermero
              )
          
          `)
        .order('id_caso', { ascending: true });
      //console.log("Casos obtenidos:", casos); // Agregar este console.log para verificar los contratantes obtenidos
      if (filters.nombre_unidad) {
        supabaseQuery = supabaseQuery.eq('cat_unidades.nombre_unidad', filters.nombre_unidad);
      }

      if (filters.nombre_paciente) {
        supabaseQuery = supabaseQuery.eq('cat_pacientes.nombre_paciente', filters.nombre_paciente);
      }

      if (filters.nombre_enfermero) {
        supabaseQuery = supabaseQuery.eq('cat_enfermeros.nombre_enfermero', filters.nombre_enfermero);
      }

      if (filters.nombre_etapa_caso) {
        supabaseQuery = supabaseQuery.eq('cat_etapas_casos.nombre_etapa_caso', filters.nombre_etapa_caso);
      }

      if (filters.fechaInicial) {
        const fechaInicialFormatted = moment(filters.fechaInicial).format('YYYY-MM-DD');
        supabaseQuery = supabaseQuery.gte('fecha_caso_inicio', fechaInicialFormatted);
      }

      if (filters.fechaFinal) {
        const fechaFinalFormatted = moment(filters.fechaFinal).format('YYYY-MM-DD');

        supabaseQuery = supabaseQuery.lte('fecha_caso_inicio', fechaFinalFormatted);
      }

      const { data: casos, error } = await supabaseQuery;
      //console.log("Actividades...", casos);
      if (error) {
        throw error;
      }
      return casos;
    } catch (error) {
      //console.error('Error al obtener casos:', error.message);
      throw error; // Propaga el error para que se maneje en el componente padre si es necesario
    }
  };


  const getData = async () => {
    try {
      dispatch(ShowLoading());
      //console.log('Estado de carga activado');
      const cargarInformacion = await fetchTiposCasos();
      setCasos(cargarInformacion);
      setShowChart(true); // Activamos la visualización de la gráfica
      dispatch(HideLoading());

    }
    catch (error) {
      //console.log(error);
      showNotification({
        title: "Error obteniendo información",
        color: "red"
      })
      dispatch(HideLoading());
    }
  };

  // Calcula los totales
  const totalCasos = casos.length;
  const casosConcluidos = casos.filter(caso => caso.cat_etapas_casos.nombre_etapa_caso === 'CONCLUÍDO').length;
  const casosEnProceso = casos.filter(caso => caso.cat_etapas_casos.nombre_etapa_caso === 'EN PROCESO').length;

  // Datos para el PieChart
  const pieChartData = [
    { name: 'Casos Concluídos', value: casosConcluidos },
    { name: 'Casos en Proceso', value: casosEnProceso },
  ];


  return (
    <div>
      <Accordion defaultValue="catalogo">
        <Accordion.Item value="catalogo">
          <Accordion.Control className="AccordionControl"
          >
            Filtros de Búsqueda
          </Accordion.Control>
          <Accordion.Panel className="AccordionPanel" >
            <CasosFiltro
              filters={filters}
              setFilters={setFilters}
              onEnterPress={getData}
            />

            <Group justify='flex-start'>
              <Button leftSection={<IconSearch size={20} />} onClick={getData}>
                Generar Gráfica
              </Button>



            </Group>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginTop: '1rem' }}>
        <Card className='CardTablas' style={{ flex: '1 1 30%', background: 'grey' }}>
          <Text c={'white'} align="center" weight={500}>Total de Casos</Text>
          <Text c={'white'} align="center" size="xl" weight={700}>{totalCasos}</Text>
        </Card>
        <Card className='CardTablas' style={{ flex: '1 1 30%', background: '#009400' }}>
          <Text c={'white'} align="center" weight={500}>Casos Concluídos</Text>
          <Text c={'white'} align="center" size="xl" weight={700}>{casosConcluidos}</Text>
        </Card>
        <Card className='CardTablas' style={{ flex: '1 1 30%', background: '#D5133A' }}>
          <Text c={'white'} align="center" weight={500}>Casos en Proceso</Text>
          <Text c={'white'} align="center" size="xl" weight={700}>{casosEnProceso}</Text>
        </Card>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginBottom: '1rem' }}>
        <div style={{ flex: '1 1 35%' }}>
          <Card className='CardTablas' style={{ marginTop: '1rem' }}>
            {showChart && casos.length > 0 ? (
              <div>

                <Text>Número de casos por fecha</Text>
                <PieChartComponent data={pieChartData} />
              </div>
            ) : (
              <Text>No hay datos para mostrar.</Text>
            )}
          </Card>

        </div>
        <div style={{ flex: '1 1 60%' }}>
          <Card className='CardTablas' style={{ marginTop: '1rem' }}>
            {showChart && casos.length > 0 ? (
              <div>
                <Text>Número de casos por fecha</Text>
                <BarChartComponent casos={casos} />
              </div>
            ) : (
              <Text>No hay datos para mostrar. Haga clic en "Buscar Casos" para generar la gráfica.</Text>
            )}
          </Card>
        </div>
      </div>
    </div >
  );
}

export default CatalogoReporteBarras;
