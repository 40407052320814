import React, { useEffect, useState } from 'react'
import { supabase } from "../supabaseClient";
import UsuariosTable from '../components/UsuariosTable';

import { Group, Button, Divider, Modal, Card, TextInput, Accordion, Text } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import UsuariosForm from '../components/UsuariosForm';



function CatalogoUsuarios({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [tiposseguros, setTiposSeguros] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroNombreTipoSeguro, setFiltroNombreTipoSeguro] = useState('');


    useEffect(() => {
        //body
        try {
            const getTiposSeguros = async () => {
                dispatch(ShowLoading());
                const allTiposSeguros = await fetchTiposSeguros()
                setTiposSeguros(allTiposSeguros) //populate the react state
                // Llamar a la función contarContratantes para obtener el conteo al cargar el componente
                //contarContratantes();
                dispatch(HideLoading());
            }
            getTiposSeguros()

        } catch (error) {
            //console.error('Error:', error);
            dispatch(HideLoading());
        }
    }, [])

    /* ESTA CONST ES PARA QUERY SOLO CAT_TIPOS_CONTRATANTES */

    const fetchTiposSeguros = async () => {
        try {
            let { data: tiposseguros, error } = await supabase
                .from('profiles')
                .select(`
                id,
                username,
                email,
                acciones
                
                `)
                .order('username', { ascending: true })
            console.log("usuarios obtenidos:", tiposseguros); // Agregar este console.log para verificar los contratantes obtenidos

            if (error) {
                throw error;
            }
            return tiposseguros;
        } catch (error) {
            //console.error('Error al obtener tipos de seguros:', error.message);
            throw error; // Propaga el error para que se maneje en el componente padre si es necesario
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: tipossegurosFiltrados, error } = await supabase
                .from('profiles')
                .select(`
                id,
                username,
                email,
                acciones
                
                `)
                .ilike('username', `%${filtroNombreTipoSeguro.toLowerCase()}%`)
                .order('id', { ascending: true });

            if (error) {
                throw error;
            }
            setTiposSeguros(tipossegurosFiltrados);
        } catch (error) {
            //console.error('Error al filtrar tipos de seguro:', error.message);
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };



    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            //console.log('Estado de carga activado');
            const allTiposSeguros = await fetchTiposSeguros();
            setTiposSeguros(allTiposSeguros);
        }
        catch (error) {
            //console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddUsuario = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_usuarios &&
        permisos.catalogos.modulos.cat_usuarios.acciones &&
        permisos.catalogos.modulos.cat_usuarios.acciones.registrar_usuario === true;

    return (
        <div>
            <Card className='CardTituloCatalogos'>
                <Text
                    size="xl"
                    fw={900}

                >Catálogo Usuarios
                </Text>
            </Card>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar"
                                value={filtroNombreTipoSeguro}
                                onChange={(event) => setFiltroNombreTipoSeguro(event.target.value)}
                                onKeyPress={handleKeyPress} // Ejecuta handleKeyPress al presionar Enter
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddUsuario && (
                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar usuario
                                </Button>
                            )}

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

 
            <Card className='CardTablas'>

                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Usuario" : "Editar Usuario"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <UsuariosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar el contratante seleccionado como prop
                    />

                </Modal>
                <div style={{ overflowX: "auto" }}>
                    <UsuariosTable
                        tiposseguros={tiposseguros}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />
                </div>
            </Card>
        </div>

    );

}


export default CatalogoUsuarios;