import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button, Checkbox, Accordion, AccordionItem, rem } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";


function UsuariosForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));    

    //valores iniciales
    const [user, setUser] = useState({
        id: "",
        username: "",
        email: "",
        acciones: {
            casos: {
                activo: true,
                acciones: {
                    eliminar_caso: true,
                    modificar_caso: true,
                    registrar_caso: true,
                },
            },
            notas: {
                activo: true,
                acciones: {
                    eliminar_nota: true,
                    modificar_nota: true,
                    registrar_nota: true,
                },
            },
            cultivos: {
                activo: true,
                acciones: {
                    eliminar_cultivo: true,
                    modificar_cultivo: true,
                    registrar_cultivo: true,
                },
            },
            reportes: {
                activo: true,
                modulos: {
                    reporte_casos: { activo: false },
                    reporte_notas: { activo: true },
                    reporte_tabla1: { activo: true },
                    reporte_tabla2: { activo: true },
                    reporte_tabla3: { activo: true },
                    reporte_tabla6: { activo: true },
                    reporte_tabla9: { activo: true },
                    reporte_tabla12: { activo: true },
                },
            },
            catalogos: {
                activo: true,
                modulos: {
                    cat_usos: {
                        activo: true,
                        acciones: {
                            eliminar_uso: true,
                            modificar_uso: true,
                            registrar_uso: true,
                        },
                    },
                    cat_salas: {
                        activo: true,
                        acciones: {
                            eliminar_sala: false,
                            modificar_sala: false,
                            registrar_sala: false,
                        },
                    },
                    cat_signos: {
                        activo: true,
                        acciones: {
                            eliminar_signo: true,
                            modificar_signo: true,
                            registrar_signo: true,
                        },
                    },
                    cat_turnos: {
                        activo: true,
                        acciones: {
                            eliminar_turno: true,
                            modificar_turno: true,
                            registrar_turno: true,
                        },
                    },
                    cat_cambios: {
                        activo: true,
                        acciones: {
                            eliminar_cambio: true,
                            modificar_cambio: true,
                            registrar_cambio: true,
                        },
                    },
                    cat_manejos: {
                        activo: true,
                        acciones: {
                            eliminar_manejo: false,
                            modificar_manejo: false,
                            registrar_manejo: false,
                        },
                    },
                    cat_parches: {
                        activo: true,
                        acciones: {
                            eliminar_parche: true,
                            modificar_parche: true,
                            registrar_parche: true,
                        },
                    },
                    cat_tiposav: {
                        activo: true,
                        acciones: {
                            eliminar_tipoav: true,
                            modificar_tipoav: true,
                            registrar_tipoav: true,
                        },
                    },
                    cat_maquinas: {
                        activo: true,
                        acciones: {
                            eliminar_maquina: true,
                            modificar_maquina: true,
                            registrar_maquina: true,
                        },
                    },
                    cat_sesiones: {
                        activo: true,
                        acciones: {
                            eliminar_sesion: true,
                            modificar_sesion: true,
                            registrar_sesion: true,
                        },
                    },
                    cat_unidades: {
                        activo: true,
                        acciones: {
                            eliminar_unidad: true,
                            modificar_unidad: true,
                            registrar_unidad: true,
                        },
                    },
                    cat_usuarios: {
                        activo: true,
                        acciones: {
                            eliminar_usuario: false,
                            modificar_usuario: true,
                            registrar_usuario: false,
                        },
                    },
                    cat_pacientes: {
                        activo: true,
                        acciones: {
                            eliminar_paciente: true,
                            modificar_paciente: true,
                            registrar_paciente: true,
                        },
                    },
                    cat_enfermeros: {
                        activo: true,
                        acciones: {
                            eliminar_enfermero: true,
                            modificar_enfermero: true,
                            registrar_enfermero: true,
                        },
                    },
                    cat_evoluciones: {
                        activo: true,
                        acciones: {
                            eliminar_evolucion: true,
                            modificar_evolucion: true,
                            registrar_evolucion: true,
                        },
                    },
                    cat_secreciones: {
                        activo: true,
                        acciones: {
                            eliminar_secrecion: true,
                            modificar_secrecion: true,
                            registrar_secrecion: true,
                        },
                    },
                    cat_antibioticos: {
                        activo: true,
                        acciones: {
                            eliminar_antibiotico: true,
                            modificar_antibiotico: true,
                            registrar_antibiotico: true,
                        },
                    },
                    cat_conclusiones: {
                        activo: true,
                        acciones: {
                            eliminar_conclusion: true,
                            modificar_conclusion: true,
                            registrar_conclusion: true,
                        },
                    },
                    cat_etapas_casos: {
                        activo: true,
                        acciones: {
                            eliminar_etapa_caso: false,
                            modificar_etapa_caso: false,
                            registrar_etapa_caso: false,
                        },
                    },
                    cat_procedencias: {
                        activo: true,
                        acciones: {
                            eliminar_procedencia: true,
                            modificar_procedencia: true,
                            registrar_procedencia: true,
                        },
                    },
                    cat_tiposcultivos: {
                        activo: true,
                        acciones: {
                            eliminar_tipocultivo: true,
                            modificar_tipocultivo: true,
                            registrar_tipocultivo: true,
                        },
                    },
                    cat_sensibilidades: {
                        activo: true,
                        acciones: {
                            eliminar_sensibilidad: true,
                            modificar_sensibilidad: true,
                            registrar_sensibilidad: true,
                        },
                    },
                    cat_resultados_cultivos: {
                        activo: true,
                        acciones: {
                            eliminar_resultado: true,
                            modificar_resultado: true,
                            registrar_resultado: true,
                        },
                    },
                    cat_sintomas_intradialisis: {
                        activo: true,
                        acciones: {
                            eliminar_sintoma: true,
                            modificar_sintoma: true,
                            registrar_sintoma: true,
                        },
                    },
                },
            },
            tratamientos: {
                activo: true,
                acciones: {
                    eliminar_tratamiento: true,
                    modificar_tratamiento: true,
                    registrar_tratamiento: true,
                },
            },
        },
    });



    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                id: transactionData.id,
                username: transactionData.username,
                email: transactionData.email,
                acciones: transactionData.acciones // Actualiza acciones con los datos existentes

            });
            //console.log("Transaction data2:", transactionData);
        }
    }, [formMode, transactionData]);

    const handleCheckboxChange = (event, section, module, action) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
            ...prevUser,
            acciones: {
                ...prevUser.acciones,
                [section]: {
                    ...prevUser.acciones[section],
                    modulos: {
                        ...prevUser.acciones[section].modulos,
                        [module]: {
                            ...prevUser.acciones[section].modulos[module],
                            acciones: {
                                ...prevUser.acciones[section].modulos[module].acciones,
                                [action]: isChecked
                            }
                        }
                    }
                }
            }
        }));
    };

    const handleCheckboxChangeActivo = (event, section, module) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
            ...prevUser,
            acciones: {
                ...prevUser.acciones,
                [section]: {
                    ...prevUser.acciones[section],
                    modulos: {
                        ...prevUser.acciones[section].modulos,
                        [module]: {
                            ...prevUser.acciones[section].modulos[module],
                            activo: isChecked
                        }
                    }
                }
            }
        }));
    };

    const handleCheckboxChangeActivo2 = (event, section) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
            ...prevUser,
            acciones: {
                ...prevUser.acciones,
                [section]: {
                    ...prevUser.acciones[section],
                    activo: isChecked
                }
            }
        }));
    };



    const handleCheckboxChangePagos = (event, action) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
            ...prevUser,
            acciones: {
                ...prevUser.acciones,
                financieros: {
                    ...prevUser.acciones.financieros,
                    submenus: {
                        ...prevUser.acciones.financieros.submenus,
                        pagos: {
                            ...prevUser.acciones.financieros.submenus.pagos,
                            acciones: {
                                ...prevUser.acciones.financieros.submenus.pagos.acciones,
                                [action]: isChecked
                            }
                        }
                    }
                }
            }
        }));
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateContratante = async () => {
        try {
            await supabase
                .from('profiles')
                .update({
                    username: user.username,
                    acciones: user.acciones // Actualiza los permisos junto con el nombre de usuario
                })
                .eq('id', user.id);

            showNotification({
                title: 'Usuario actualizado',
                message: 'Usuario actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            //console.error('Error al actualizar el estatus de pago:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el estatus de pago.',
                color: 'red',
            });
        }
    };

    const handleCheckboxChangeSubmenu = (event, submenu) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
            ...prevUser,
            acciones: {
                ...prevUser.acciones,
                academia: {
                    ...prevUser.acciones.academia,
                    submenus: {
                        ...prevUser.acciones.academia.submenus,
                        [submenu]: {
                            ...prevUser.acciones.academia.submenus[submenu],
                            activo: isChecked
                        }
                    }
                }
            }
        }));
    };

    const handleCheckboxChangeAccionAcademia = (event, submenu, action) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
            ...prevUser,
            acciones: {
                ...prevUser.acciones,
                academia: {
                    ...prevUser.acciones.academia,
                    submenus: {
                        ...prevUser.acciones.academia.submenus,
                        [submenu]: {
                            ...prevUser.acciones.academia.submenus[submenu],
                            acciones: {
                                ...prevUser.acciones.academia.submenus[submenu].acciones,
                                [action]: isChecked
                            }
                        }
                    }
                }
            }
        }));
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {
                // Buscar el id_tipo_contratante correspondiente al nombre_tipo_contratante seleccionado

                const { error } = await supabase
                    .from('profiles')
                    .insert({
                        username: user.username
                    });
                showNotification({
                    title: 'Usuario agregado',
                    message: 'Usuario ha sido agregado con éxito',
                    color: 'green',
                });

                if (error) {
                    throw error;
                }
            } else if (formMode === 'edit') {
                await handleUpdateContratante();
            }
            dispatch(HideLoading());
            setShowForm(false);
            getData();
        } catch (error) {
            //console.log(error)
            //console.error('Error al agregar/actualizar el usuario:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el usuario.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action="" onSubmit={handleSubmit}>
                <Group style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                    <TextInput
                        disabled
                        name="id"
                        label="ID"
                        //onChange={handleChange}
                        style={{ width: '300px' }}
                        value={user.id}
                    />
                    <TextInput
                        name="username"
                        label="Nombre usuario"
                        style={{ width: '300px' }}
                        required
                        onChange={handleChange}
                        value={user.username}
                        placeholder='Estatus de pago'
                    />
                    <TextInput
                        name="email"
                        label="Email"
                        style={{ width: '300px' }}
                        disabled
                        onChange={handleChange}
                        value={user.email}
                        placeholder='Email'
                    />
                </Group>
                <Group style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                    <Accordion variant='contained'>
                        <Accordion.Item value="cat">
                            <Accordion.Control className="AccordionControlUsuariosForm">
                                Catálogos
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Accordion>
                                    <Accordion.Item value='1.1 Antibióticos'>
                                        <Accordion.Control className="AccordionControl2">Antibióticos</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_antibioticos.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_antibioticos', 'activo')}
                                                    label="Ver Antibióticos"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_antibioticos.acciones.registrar_antibiotico}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_antibioticos', 'registrar_antibiotico')}
                                                    label="Registrar antibiótico"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_antibioticos.acciones.modificar_antibiotico}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_antibioticos', 'modificar_antibiotico')}
                                                    label="Modificar Antibiótico"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_antibioticos.acciones.eliminar_antibiotico}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_antibioticos', 'eliminar_antibiotico')}
                                                    label="Eliminar Antibiótico"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='Conclusiones casos'>
                                        <Accordion.Control className="AccordionControl2">Conclusiones casos</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_conclusiones.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_conclusiones', 'activo')}
                                                    label="Ver Conclusiones casos"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_conclusiones.acciones.registrar_conclusion}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_conclusiones', 'registrar_conclusion')}
                                                    label="Registrar conclusión caso"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_conclusiones.acciones.modificar_conclusion}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_antibioticos', 'modificar_antibiotico')}
                                                    label="Modificar conclusión caso"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_conclusiones.acciones.eliminar_conclusion}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_antibioticos', 'eliminar_antibiotico')}
                                                    label="Eliminar conclusión caso"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='Condiciones parche'>
                                        <Accordion.Control className="AccordionControl2">Condiciones parche</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_parches.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_parches', 'activo')}
                                                    label="Ver Condiones parches"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_parches.acciones.registrar_parche}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_conclusiones', 'registrar_parche')}
                                                    label="Registrar condición parche"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_parches.acciones.modificar_parche}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_conclusiones', 'modificar_parche')}
                                                    label="Modificar condición parche"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_parches.acciones.eliminar_parche}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_conclusiones', 'eliminar_parche')}
                                                    label="Eliminar condición parche"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='Enfermeros'>
                                        <Accordion.Control className="AccordionControl2">Enfermeros</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_enfermeros.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_enfermeros', 'activo')}
                                                    label="Ver Condiones parches"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_enfermeros.acciones.registrar_enfermero}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_enfermeros', 'registrar_enfermero')}
                                                    label="Registrar enfermero"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_enfermeros.acciones.modificar_enfermero}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_enfermeros', 'modificar_enfermero')}
                                                    label="Modificar enfermero"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_enfermeros.acciones.eliminar_enfermero}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_enfermeros', 'eliminar_enfermero')}
                                                    label="Eliminar enfermero"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='Maquinas'>
                                        <Accordion.Control className="AccordionControl2">Máquinas</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_maquinas.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_maquinas', 'activo')}
                                                    label="Ver Máquinas"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_maquinas.acciones.registrar_maquina}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_maquinas', 'registrar_maquina')}
                                                    label="Registrar máquina"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_maquinas.acciones.modificar_maquina}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_maquinas', 'modificar_maquina')}
                                                    label="Modificar máquina"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_maquinas.acciones.eliminar_maquina}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_maquinas', 'eliminar_maquina')}
                                                    label="Eliminar máquina"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='Maquinas'>
                                        <Accordion.Control className="AccordionControl2">Sesiones</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_maquinas.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_maquinas', 'activo')}
                                                    label="Ver Máquinas"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_maquinas.acciones.registrar_maquina}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_maquinas', 'registrar_maquina')}
                                                    label="Registrar máquina"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_maquinas.acciones.modificar_maquina}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_maquinas', 'modificar_maquina')}
                                                    label="Modificar máquina"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_maquinas.acciones.eliminar_maquina}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_maquinas', 'eliminar_maquina')}
                                                    label="Eliminar máquina"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                


                                <Accordion>
                                    <Accordion.Item value='Usuarios'>
                                        <Accordion.Control className="AccordionControl2">Usuarios</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_usuarios.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_usuarios', 'activo')}
                                                    label="Ver usuarios "
                                                >
                                                </Checkbox>


                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_usuarios.acciones.modificar_usuario}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_usuarios', 'modificar_usuario')}
                                                    label="Modificar usuarios "

                                                >
                                                </Checkbox>

                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>

                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion variant='contained'>
                        <Accordion.Item value="casos">
                            <Accordion.Control className="AccordionControlUsuariosForm">
                                Casos
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Stack>
                                    <Checkbox
                                        checked={user.acciones.casos.activo}
                                        onChange={(e) => handleCheckboxChangeActivo2(e, 'casos', 'activo')}
                                        label="Ver Casos"
                                    />
                                    <Checkbox
                                        checked={user.acciones.casos.acciones.registrar_caso}
                                        //onChange={(e) => handleCheckboxChangePolizas(e, 'registrar_caso')}
                                        label="Registrar caso"
                                    />
                                    <Checkbox
                                        checked={user.acciones.casos.acciones.modificar_caso}
                                        //onChange={(e) => handleCheckboxChangePolizas(e, 'modificar_caso')}
                                        label="Modificar caso"
                                    />
                                    <Checkbox
                                        checked={user.acciones.casos.acciones.eliminar_caso}
                                        //onChange={(e) => handleCheckboxChangePolizas(e, 'eliminar_caso')}
                                        label="Eliminar caso"
                                    />
                                </Stack>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>


                </Group>
                <Group>

                    <Accordion variant='contained'>
                        <Accordion.Item value="reportes">
                            <Accordion.Control className="AccordionControlUsuariosForm">
                                Reportes
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Stack>

                                    <Checkbox
                                        checked={user.acciones.reportes.modulos.reporte_casos.activo}
                                        //onChange={(e) => handleCheckboxChangeReportes(e,'cumpleaneros')}
                                        label='Ver reporte casos'
                                    />
                                    <Checkbox
                                        checked={user.acciones.reportes.modulos.reporte_notas.activo}
                                        //onChange={(e) => handleCheckboxChangeReportes(e,'lista_regalos')}
                                        label='Ver reporte lista regalos'
                                    />
                                    <Checkbox
                                        checked={user.acciones.reportes.modulos.reporte_tabla1.activo}
                                        //onChange={(e) => handleCheckboxChangeReportes(e,'polizas_nuevas')}
                                        label='Ver reporte pólizas nuevas'
                                    />
                                    <Checkbox
                                        checked={user.acciones.reportes.modulos.reporte_tabla2.activo}
                                        //onChange={(e) => handleCheckboxChangeReportes(e,'nivel_cobranza')}
                                        label='Ver reporte nivel cobranza'
                                    />



                                </Stack>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>

                </Group>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Usuario" : "Editar Usuario"}
                    </Button>
                </div>
            </form>
        </div>
    );
}



export default UsuariosForm;