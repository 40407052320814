import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import CasosTable from '../components/CasosTable';
import CasoForm from '../components/CasoForm';
import { Group, Button, Text, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import CasosFiltro from '../components/CasosFiltro';
import { IconSearch } from '@tabler/icons-react';
import moment from "moment";
import CasosCard from '../components/CasosCard';


function CatalogoCasos({ user, permisos }) {
    const [view, setView] = React.useState("cards");

    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [casos, setCasos] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();

    const [filters, setFilters] = useState({
        fechaInicial: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Primer día del mes actual,
        fechaFinal: new Date(),
        nombre_unidad: "",
        nombre_paciente: "",
        nombre_enfermero: "",
        nombre_etapa_caso: "",
        nombre_turno: "",
        nombre_sala: "",
        cultivos: "todos",  // Puede ser "todos", "con", "sin",
        id_caso: "",
        nombre_manejo: "",
        nombre_evolucion: "",
        nombre_cambio: "",
        clasificacion_uso: "",



    });


    const fetchTiposCasos = async () => {
        try {
            let supabaseQuery = supabase
                .from('casos')
                .select(`
                id_caso,
                usuario_creador_caso,
                fecha_caso_inicio,
                fecha_caso_fin,
                fecha_inicio_sintomas,
                numero_caso,
                id_unidad,
                cat_unidades!inner (
                    id_unidad,
                    nombre_unidad
                    ),
                id_etapa_caso,
                cat_etapas_casos!inner (
                    id_etapa_caso,
                    nombre_etapa_caso
                    ),
                id_paciente,
                cat_pacientes!inner (
                    id_paciente,
                    nombre_paciente
                    ),
                id_enfermero,
                cat_enfermeros!inner (
                    id_enfermero,
                    nombre_enfermero
                    ),
                    casos_sintomas (
                    id_caso_sintoma,
                    id_sintoma_intradialisis,
                        cat_sintomas_intradialisis(
                            id_sintoma_intradialisis,
                            nombre_sintoma_intradialisis
                        )
                    ),
                id_tipoav,
                cat_tiposav!inner(
                    id_tipoav,
                    nombre_tipoav,
                    clasificacion_tipoav
                    ),
                id_procedencia,
                cat_procedencias!inner(
                    id_procedencia,
                    nombre_procedencia
                    ),
                id_sala,
                cat_salas!inner(
                    id_sala,
                    nombre_sala    
                    ),
                fecha_hemodialisis_previa,
                id_turno,
                cat_turnos!inner(
                    id_turno,
                    nombre_turno
                    ),
                fiebre,
                temperatura,
                fecha_inicio_fiebre,
                casos_signos (
                    id_caso_signo,
                    id_signo,
                        cat_signos(
                            id_signo,
                            nombre_signo
                        )
                    ),
                casos_parches (
                    id_caso_parche,
                    id_parche,
                        cat_parches(
                            id_parche,
                            nombre_parche
                        )
                    ),
                id_uso,
                cat_usos!inner(
                    id_uso,
                    nombre_uso,
                    clasificacion_uso
                    ),
                cultivos (
                    id_cultivo,
                    id_resultado_cultivo,
                    cat_resultados_cultivos (
                        id_resultado_cultivo,
                        nombre_resultado_cultivo
                    )
                ),
                tratamientos (
                    id_tratamiento,
                    fecha_inicio_tratamiento,
                    fecha_fin_tratamiento,
                    nombre_tratamiento
                ),
                notas (
                    id_nota,
                    id_caso,
                    fecha_nota,
                    nombre_nota,
                    id_manejo,
                    cat_manejos!inner(
                        id_manejo,
                        nombre_manejo
                        ),
                    id_evolucion,
                    cat_evoluciones!inner(
                        id_evolucion,
                        nombre_evolucion
                        ),
                    id_cambio,
                    cat_cambios!inner(
                        id_cambio,
                        nombre_cambio
                        )
                ),
                casos_secreciones (
                    id_caso_secrecion,
                    id_secrecion,
                        cat_secreciones(
                            id_secrecion,
                            nombre_secrecion
                        )
                    ),
                id_maquina,
                cat_maquinas(
                    id_maquina,
                    nombre_maquina
                    ),
                id_conclusion,
                cat_conclusiones(
                    id_conclusion,
                    nombre_conclusion
                    )
            
                
                `)
                .order('id_caso', { ascending: true });

            //console.log("Casos obtenidos:", casos); // Agregar este console.log para verificar los contratantes obtenidos
            if (filters.nombre_unidad) {
                supabaseQuery = supabaseQuery.eq('cat_unidades.nombre_unidad', filters.nombre_unidad);
            }

            //if (filters.nombre_paciente) {
            //    supabaseQuery = supabaseQuery.eq('cat_pacientes.nombre_paciente', filters.nombre_paciente);
            //}

            // Aplica el filtro con ilike si hay texto ingresado, para evitar el autocomplete de pacientes
            if (filters.nombre_paciente) {
                supabaseQuery = supabaseQuery.ilike('cat_pacientes.nombre_paciente', `%${filters.nombre_paciente}%`);
            }

            if (filters.nombre_enfermero) {
                supabaseQuery = supabaseQuery.eq('cat_enfermeros.nombre_enfermero', filters.nombre_enfermero);
            }

            if (filters.nombre_etapa_caso) {
                supabaseQuery = supabaseQuery.eq('cat_etapas_casos.nombre_etapa_caso', filters.nombre_etapa_caso);
            }

            if (filters.nombre_turno) {
                supabaseQuery = supabaseQuery.eq('cat_turnos.nombre_turno', filters.nombre_turno);
            }

            if (filters.nombre_sala) {
                supabaseQuery = supabaseQuery.eq('cat_salas.nombre_sala', filters.nombre_sala);
            }

            if (filters.clasificacion_tipoav) {
                supabaseQuery = supabaseQuery.eq('cat_tiposav.clasificacion_tipoav', filters.clasificacion_tipoav);
            }

            if (filters.fechaInicial) {
                const fechaInicialFormatted = moment(filters.fechaInicial).format('YYYY-MM-DD');
                supabaseQuery = supabaseQuery.gte('fecha_caso_inicio', fechaInicialFormatted);
            }

            if (filters.fechaFinal) {
                const fechaFinalFormatted = moment(filters.fechaFinal).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.lte('fecha_caso_inicio', fechaFinalFormatted);
            }

            if (filters.id_caso) {
                supabaseQuery = supabaseQuery.eq('id_caso', filters.id_caso);
            }

            if (filters.nombre_manejo) {
                supabaseQuery = supabaseQuery.eq('notas.cat_manejos.nombre_manejo', filters.nombre_manejo);
            }

            if (filters.nombre_evolucion) {
                supabaseQuery = supabaseQuery.eq('notas.cat_evoluciones.nombre_evolucion', filters.nombre_evolucion);
            }

            if (filters.nombre_cambio) {
                supabaseQuery = supabaseQuery.eq('notas.cat_cambios.nombre_cambio', filters.nombre_cambio);
            }

            // filtro clasificacion_uso
            if (filters.clasificacion_uso) {
                supabaseQuery = supabaseQuery.eq('cat_usos.clasificacion_uso', filters.clasificacion_uso);
            }



            // Ejecutar la consulta y obtener los datos
            const { data: casos, error } = await supabaseQuery;

            if (error) {
                throw error;
            }

            // Filtrar los casos según los cultivos
            let casosFiltrados = casos;

            if (filters.cultivos === 'con') {
                // Filtrar casos con cultivos (donde el array de cultivos no está vacío)
                casosFiltrados = casos.filter(caso => caso.cultivos.length > 0);
            } else if (filters.cultivos === 'sin') {
                // Filtrar casos sin cultivos (donde el array de cultivos está vacío)
                casosFiltrados = casos.filter(caso => caso.cultivos.length === 0);
            }

            console.log("Casos...", casosFiltrados)

            return casosFiltrados;
        } catch (error) {
            console.error('Error al obtener casos:', error.message);
            throw error;
        }

    };


    const getData = async () => {
        try {
            dispatch(ShowLoading());
            //console.log('Estado de carga activado');
            const cargarInformacion = await fetchTiposCasos();
            setCasos(cargarInformacion);
            dispatch(HideLoading());

        }
        catch (error) {
            //console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddCaso = permisos &&
        permisos.casos &&
        permisos.casos.acciones &&
        permisos.casos.acciones.registrar_caso === true;


    return (
        <div>
            <Card className='CardTituloCatalogos'
                mb={'15px'}
            >
                <Text
                    size="xl"
                    fw={900}

                >Casos de Infecciones
                </Text>
            </Card>

            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <CasosFiltro
                            filters={filters}
                            setFilters={setFilters}
                            onEnterPress={getData}
                        />

                        <Group justify='flex-start'>
                            <Button
                                variant="gradient"
                                gradient={{ from: 'blue', to: '#00497A', deg: 90 }}
                                leftSection={<IconSearch size={20} />}
                                onClick={getData}
                            >
                                Buscar Casos
                            </Button>
                            <Button.Group>
                                <Button
                                    color='#005187'
                                    variant={view === 'cards' ? "filled" : "outline"}
                                    onClick={() => setView("cards")}
                                >
                                    Ver Tarjetas
                                </Button>
                                <Button
                                    color='#005187'
                                    variant={view === 'table' ? "filled" : "outline"}
                                    onClick={() => setView("table")}
                                >
                                    Ver Tabla
                                </Button>
                            </Button.Group>

                            {canAddCaso && (
                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Caso
                                </Button>
                            )}

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card style={{ backgroundColor: view === 'cards' ? '#F0F0F0' : 'white' }}>

                <Modal
                    //size="auto"
                    fullScreen
                    //size={'80%'}
                    title={formMode === "add" ? "Agregar Caso" : "Editar Caso"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                    styles={{
                        header: {
                            backgroundColor: '#00497A', // Cambia este color al que prefieras
                            color: 'white' // Cambia el color del texto si es necesario
                        },
                        body: {
                            backgroundColor: '#f0f0f0',
                        },
                        close: {
                            color: '#ffffff',
                            backgroundColor: 'transparent',
                        },
                        inner: {
                            backgroundColor: '#f0f0f0', // Asegura que todo el modal tenga fondo gris
                        },
                        content: {
                            backgroundColor: '#f0f0f0', // Fondo del contenido principal del modal
                        }
                    }}
                >
                    <CasoForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar caso seleccionada como prop
                        user={user}
                        // Pasar los permisos para las notas
                        puedeEliminarNota={permisos.notas?.acciones?.eliminar_nota}
                        puedeModificarNota={permisos.notas?.acciones?.modificar_nota}
                        puedeRegistrarNota={permisos.notas?.acciones?.registrar_nota}
                        puedeEliminarTratamiento={permisos.tratamientos?.acciones?.eliminar_tratamiento}
                        puedeModificarTratamiento={permisos.tratamientos?.acciones?.modificar_tratamiento}
                        puedeRegistrarTratamiento={permisos.tratamientos?.acciones?.registrar_tratamiento}
                        puedeEliminarCultivo={permisos.cultivos?.acciones?.eliminar_cultivo}
                        puedeModificarCultivo={permisos.cultivos?.acciones?.modificar_cultivo}
                        puedeRegistrarCultivo={permisos.cultivos?.acciones?.registrar_cultivo}
                    />

                </Modal>
                <div style={{ overflowX: "auto" }}>
                    {view === 'table' ? (

                        <CasosTable
                            casos={casos}
                            setSelectedTransaction={setSelectedTransaction}
                            setFormMode={setFormMode}
                            setShowForm={setShowForm}
                            getData={getData}
                            user={user}
                            permisos={permisos}
                        />
                    ) : (
                        <CasosCard
                            casos={casos}
                            setSelectedTransaction={setSelectedTransaction}
                            setFormMode={setFormMode}
                            setShowForm={setShowForm}
                            getData={getData}
                            user={user}
                            permisos={permisos}
                        />
                    )}
                </div>
            </Card>
        </div>

    );

}


export default CatalogoCasos;